import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import LanguageDetector from 'i18next-browser-languagedetector'

i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: {
      en: {
        translation: {
            back: "Back",
            hello_hi: "Hi, I'm",
            hello_im: "I am",
            hello_fullstack: 'a <span class="highlighted-text">fullstack</span> developer',
            hello_backend: 'a <span class="highlighted-text">backend</span> developer',
            hello_frontend: 'a <span class="highlighted-text">frontend</span> developer', 
            hello_colleague: '<span class="highlighted-text"> your next colleague</span>',
            hello_passionate: 'passionate about continuous improvement and solving technical challenges.',
            aboutme_header: 'About me',
            aboutme_desc:"I am a 28-year-old backend developer passionate about exploring different technologies. I enjoy designing robust and high-performance solutions while experimenting with various languages and architectures.",
            aboutme_more:"I also like to experiment by developing chess engines with different technologies. At the same time, I am building my own Kubernetes cluster at home, applying infrastructure as code principles and DevOps practices to automate and optimize my deployments.",
            contact_header:"Let's start working together ! 💻",
            contact_details:"My contacts",
            contact_discord:"Or get in touch with me on :",
            resume_header:"Check out my resume !",
            resume_button:"Download",
            resume_source: new URL('./assets/cv-en.pdf', import.meta.url).href,
            now_header:"Now",
            now_current_activity: "Currently working on personal projects :",
            projects_explore: "Have a look at my projects",
            timezone_header: "Current Time",
            projects_list: " Personal Projects",
            projects_to_come: "Upcoming Features",
            projects_github: "View on Github",
            projects_demo: "Try the demo",
            projects_backend_technos: "Back-end",
            projects_frontend_technos: "Front-end",
            projects_other_technos: "Others",
            blog_list: "Blog",
            blog_explore: "Explore my blog",
            blog_checkout: "Check out the last articles",
        }
      },
      fr: {
        translation: {
            back: "Retour",
            hello_hi: "Salut, je suis",
            hello_im: "Je suis",
            hello_fullstack: 'un développeur <span class="highlighted-text">fullstack</span>',
            hello_backend: 'un développeur <span class="highlighted-text">backend</span>',
            hello_frontend: 'un développeur <span class="highlighted-text">frontend</span>',
            hello_colleague: '<span class="highlighted-text">votre prochain collègue</span>',
            hello_passionate: "passionné par l'amélioration continue et la résolution de défis techniques.",
            aboutme_header: 'A propos de moi',
            aboutme_desc : "Je suis un développeur backend de 28 ans, passionné par l’exploration des différentes technologies. J’aime concevoir des solutions robustes et performantes tout en expérimentant divers langages et architectures.",
            aboutme_more : "J’aime aussi expérimenter en développant des moteurs d’échecs avec différentes technologies. En parallèle, je construis mon propre cluster Kubernetes chez moi, mettant en pratique l’infrastructure as code et les principes DevOps pour automatiser et optimiser mes déploiements.",
            contact_header:"Commençons à travailler ensemble ! 💻",
            contact_details:"Mes coordonnées",
            contact_discord:"Ou contactez-moi sur Discord",
            resume_header:"Consultez mon cv !",
            resume_button:"Télécharger",
            resume_source: new URL('./assets/cv-fr.pdf', import.meta.url).href,
            now_header:"En ce moment",
            now_current_activity: "Je travaille sur des projets personnels:",
            projects_explore: "Jettez un oeil à mes projets",
            timezone_header: "Heure actuelle",
            projects_list: "Mes Projets Perso",
            projects_to_come: "Fonctionnalités à venir",
            projects_github: "Voir sur Github",
            projects_demo: "Essayer la démo",
            projects_backend_technos: "Back-end",
            projects_frontend_technos: "Front-end",
            projects_other_technos: "Autres",
            blog_list: "Blog",
            blog_explore: "Explorez mon blog",
            blog_checkout: "Consultez les derniers articles",
        }
      }
    }
  });

export default function (app) {
  app.use(I18NextVue, { 
    i18next,
    rerenderOn: ['initialized', 'languageChanged', 'loaded'],
   })
  return app
}